import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {IAjaxCamData, IAjaxCamMetaData, ExtendedIAjaxCamData} from '../../../firebase/types/ajaxcam.type';
import {DeviceName, AjaxCamChartData, AjaxCamChartDisplay} from '../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen, secondsToIso } from '../../../utils/timestamp';
import LuminaireDataService from "../../../firebase/services/luminaire.service";
import {HistoryIAjaxCamData} from '../../../firebase/types/ajaxcam.type';
import { modeToNumber } from '../../../utils/acstatus';
import './modal.css';
import './buttonstyles.css';

interface CamModalProps {
  show: boolean;
  onHide: () => void;
  brightness: number;
  setBrightness: (value: number) => void;
  deviceIdSelected:string;
}

type State = {
  flattenedPointset: Array<ExtendedIAjaxCamData>,
  metadata: Array<IAjaxCamMetaData>,
  brickschema: {},
  loading: false,
  device: [],
  chartData: AjaxCamChartData;
  chartDisplay: AjaxCamChartDisplay;
};

class CamModal extends Component<CamModalProps, State> {
  unsubscribe: () => void;

  componentDidUpdate(prevProps: CamModalProps) {
    // Check if deviceIdSelected has changed
    if (this.props.deviceIdSelected !== prevProps.deviceIdSelected && this.props.deviceIdSelected.startsWith("PIR")) {
        this.runFirstDatabaseQuery(); // Call the function to fetch new data based on the new deviceIdSelected
    }
}

  componentDidMount() {
    // this.fetchTableData();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
  }
  }

  constructor(props: CamModalProps) {
    super(props);
    
    this.state = {
      flattenedPointset: [],
      metadata: [],
      brickschema: {},
      loading: false,
      device: [],
      chartData:[
        { timestamp: "2022-08-28T10:50:00.000Z", 
          photos_per_demand: 1,
          battery_charge_level_percentage: 1,
          issues_count: 1,
          assigned_extender: 1,
          cms_device_index: 1,
          temperature: 1,
          device_transmission_power_mode: 1,
          device_transmission_power_mode_value: 1,
          arm_delay_seconds: 1,
          alarm_delay_seconds: 1,
          arm_delay_seconds_in_night_mode: 1,
          alarm_delay_seconds_in_night_mode: 1,
          sensitivity: 1,
          photos_per_alarm: 1,
          alarm_with_photos_limit_per_arming_session: 1,
          compare_photos_per_demand: 1,
          compare_battery_charge_level_percentage: 1,
          compare_issues_count: 1,
          compare_assigned_extender: 1,
          compare_cms_device_index: 1,
          compare_temperature: 1,
          compare_device_transmission_power_mode: 1,
          compare_device_transmission_power_mode_value: 1,
          compare_arm_delay_seconds: 1,
          compare_alarm_delay_seconds: 1,
          compare_arm_delay_seconds_in_night_mode: 1,
          compare_alarm_delay_seconds_in_night_mode: 1,
          compare_sensitivity: 1,
          compare_photos_per_alarm: 1,
          compare_alarm_with_photos_limit_per_arming_session: 1
        },
        { timestamp: "2022-09-04T08:30:00.000Z", 
          photos_per_demand: 1,
          battery_charge_level_percentage: 1,
          issues_count: 1,
          assigned_extender: 1,
          cms_device_index: 1,
          temperature: 1,
          device_transmission_power_mode: 1,
          device_transmission_power_mode_value: 1,
          arm_delay_seconds: 1,
          alarm_delay_seconds: 1,
          arm_delay_seconds_in_night_mode: 1,
          alarm_delay_seconds_in_night_mode: 1,
          sensitivity: 1,
          photos_per_alarm: 1,
          alarm_with_photos_limit_per_arming_session: 1,
          compare_photos_per_demand: 1,
          compare_battery_charge_level_percentage: 1,
          compare_issues_count: 1,
          compare_assigned_extender: 1,
          compare_cms_device_index: 1,
          compare_temperature: 1,
          compare_device_transmission_power_mode: 1,
          compare_device_transmission_power_mode_value: 1,
          compare_arm_delay_seconds: 1,
          compare_alarm_delay_seconds: 1,
          compare_arm_delay_seconds_in_night_mode: 1,
          compare_alarm_delay_seconds_in_night_mode: 1,
          compare_sensitivity: 1,
          compare_photos_per_alarm: 1,
          compare_alarm_with_photos_limit_per_arming_session: 1
        }
      ],
      chartDisplay:{
        photos_per_demand: 1,
          battery_charge_level_percentage: true,
          issues_count: true,
          assigned_extender: true,
          cms_device_index: true,
          temperature: true,
          device_transmission_power_mode: true,
          device_transmission_power_mode_value: true,
          arm_delay_seconds: true,
          alarm_delay_seconds: true,
          arm_delay_seconds_in_night_mode: true,
          alarm_delay_seconds_in_night_mode: true,
          sensitivity: true,
          photos_per_alarm: true,
          alarm_with_photos_limit_per_arming_session: true,
          compare_photos_per_demand: false,
          compare_battery_charge_level_percentage: false,
          compare_issues_count: false,
          compare_assigned_extender: false,
          compare_cms_device_index: false,
          compare_temperature: false,
          compare_device_transmission_power_mode: false,
          compare_device_transmission_power_mode_value: false,
          compare_arm_delay_seconds: false,
          compare_alarm_delay_seconds: false,
          compare_arm_delay_seconds_in_night_mode: false,
          compare_alarm_delay_seconds_in_night_mode: false,
          compare_sensitivity: false,
          compare_photos_per_alarm: false,
          compare_alarm_with_photos_limit_per_arming_session: false

      },
    };
    this.runFirstDatabaseQuery();
    this.unsubscribe = () => { };
  }

  flattenObjects(historyObjectArray: HistoryIAjaxCamData[]){
    console.log('flattenObjects');
    let chartData: AjaxCamChartData = historyObjectArray.map(item => ({
        timestamp: secondsToIso(item.timestamp.seconds),
        photos_per_demand: item.points.photos_per_demand?.present_value ?? 0,
        battery_charge_level_percentage: item.points.battery_charge_level_percentage?.present_value ?? 0,
        temperature: item.points.temperature?.present_value ?? 0,
        device_transmission_power_mode: item.points.device_transmission_power_mode?.present_value ?? 0,
        device_transmission_power_mode_value: item.points.device_transmission_power_mode_value?.present_value ?? 0,
        arm_delay_seconds: item.points.arm_delay_seconds?.present_value ?? 0,
        alarm_delay_seconds: item.points.alarm_delay_seconds?.present_value ?? 0,
        compare_photos_per_demand:  0,
        compare_battery_charge_level_percentage:  0,
        compare_temperature:  0,
        compare_device_transmission_power_mode: 0,
        compare_device_transmission_power_mode_value: 0,
        compare_arm_delay_seconds: 0,
        compare_alarm_delay_seconds: 0,
      }));
      console.log('flattenObjects' + chartData.length);
    return chartData;
};

  checkLevel(deviceId:String) {
    // Split the string by '-' and check if it has the expected format
    const parts = deviceId.split('-');
    if (parts.length === 2) {
        const firstTwoDigits = parts[1].slice(0, 2); // Extract the first two digits after '-'

        if (firstTwoDigits === "00") {
            return "Level-01";
        } else if (firstTwoDigits === "01") {
            return "Level-00";
        } else {
            return "Level-01";
        }
    } else {
        return "Level-01";
    }
}

  runFirstDatabaseQuery = async () => {  
    let queryResult: HistoryIAjaxCamData[] = [];
    // Set the end date to the current time
    const end = new Date();
    const deviceIdSelected = this.props.deviceIdSelected;

    console.log("modal runFirstDatabaseQuery:" + deviceIdSelected)

    // Set the start date to 8 hours before the end date
    const start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
    console.log('runFirstDatabaseQuery startDate:' + start + ' endDate:' + end);

    //LuminaireDataService.getDeviceTimeseries(this.props.level
    LuminaireDataService.getDeviceTimeseries(this.checkLevel(deviceIdSelected), deviceIdSelected, start, end)
    .get()
    .then((querySnapshot: any) => {
        querySnapshot.forEach((doc:any) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            queryResult.push(doc.data());
        });
    }).then(()=> {
        console.log("runFirstDatabaseQueryResult.length:"+ queryResult.length)
        this.setState({ chartData: this.flattenObjects(queryResult) }, () => {
          // this.handleFilterData();
      });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });

  };

  render() {
    const { show, onHide, brightness, setBrightness } = this.props;
    const { chartData, chartDisplay} = this.state;

    const calculateMaxYValue = (chartData:AjaxCamChartData, chartDisplay:AjaxCamChartDisplay) => {
      if (!Array.isArray(chartData) || chartData.length === 0) {
        return 100;
      }

      return Math.max(
        ...chartData.map(d => Math.max(
          chartDisplay.co2_level ? d.co2_level ?? 0 : 0,
          chartDisplay.total_volatile_organic_compounds ? d.total_volatile_organic_compounds ?? 0 : 0,
          chartDisplay.air_quality_index ? d.air_quality_index ?? 0 : 0,
          chartDisplay.temperature ? d.temperature ?? 0 : 0,
          chartDisplay.pressure ? d.pressure ?? 0 : 0,
          chartDisplay.humidity ? d.humidity ?? 0 : 0,
          chartDisplay.leq_sound_level ? d.leq_sound_level ?? 0 : 0,
          chartDisplay.illuminance_sensor ? d.illuminance_sensor ?? 0 : 0,
          chartDisplay.compare_co2_level ? d.compare_co2_level ?? 0 : 0,
          chartDisplay.compare_total_volatile_organic_compounds ? d.compare_total_volatile_organic_compounds ?? 0 : 0,
          chartDisplay.compare_air_quality_index ? d.compare_air_quality_index ?? 0 : 0,
          chartDisplay.compare_temperature ? d.compare_temperature ?? 0 : 0,
          chartDisplay.compare_pressure ? d.compare_pressure ?? 0 : 0,
          chartDisplay.compare_humidity ? d.compare_humidity ?? 0 : 0,
          chartDisplay.compare_leq_sound_level ? d.compare_leq_sound_level ?? 0 : 0,
          chartDisplay.compare_illuminance_sensor ? d.compare_illuminance_sensor ?? 0 : 0
        ))
      );
    };

    const RenderRechart = (chartData:AjaxCamChartData, chartDisplay:AjaxCamChartDisplay) => {
      return(
        <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData} width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={createHumanTimestamp}
            // ticks={this.calculateTicks(chartData, 7)}
            angle={-45} 
            textAnchor="end"
            height={120}
          />
          <YAxis label={{ value: "#", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} interval={0} domain={[0, calculateMaxYValue]}/> 
          {/* <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 4000]} interval={0} ticks={[0, 800, 1600, 2400, 3200, 4000]}/>  */}
          <YAxis label={{ value: "#", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 1200]} interval={0}/>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          {chartDisplay.co2_level ? (<Line yAxisId="right" type="monotone" dataKey="co2_level" stroke="#6fa8dc" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.total_volatile_organic_compounds ? (<Line yAxisId="right" type="monotone" dataKey="total_volatile_organic_compounds" stroke="#82ca9d" />) : null}
          {chartDisplay.air_quality_index ? (<Line yAxisId="left" type="monotone" dataKey="air_quality_index" stroke="#3c7692" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.temperature ? (<Line yAxisId="left" type="monotone" dataKey="temperature" stroke="#C06000" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.pressure ? (<Line yAxisId="right" type="monotone" dataKey="pressure" stroke="#8e67bf" />) : null}
          {chartDisplay.humidity ? (<Line yAxisId="left" type="monotone" dataKey="humidity" stroke="#8B0000" />) : null}
          {chartDisplay.leq_sound_level ? (<Line yAxisId="left" type="monotone" dataKey="leq_sound_level" stroke="#FF69B4" />) : null}
          {chartDisplay.illuminance_sensor ? (<Line yAxisId="right" type="monotone" dataKey="illuminance_sensor" stroke="#FFD700" />) : null}
        </LineChart>
      </ResponsiveContainer>
      );
    };

    const isLightDevice = this.props.deviceIdSelected.startsWith("LT");

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop={false}
        dialogClassName="rightAlignedModal" 
        centered 
        backdropClassName="modal-backdrop"
      >
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title>{this.props.deviceIdSelected}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <div style={{ minHeight: '500px' }}>
            {RenderRechart(chartData, chartDisplay)}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-custom">
          <Button variant="secondary" onClick={onHide}>Close</Button>
          <Button variant="primary" onClick={() => console.log('Brightness set to:', brightness)}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CamModal;