import React from "react";

interface DropdownDevicePointButtonProps {
  name: string;
  checked: boolean;
  onClick: () => void;
}

const DropdownDevicePointButton: React.FC<DropdownDevicePointButtonProps> = ({
  name,
  checked,
  onClick,
}) => {
  return (
    <button
      className={`py-2 pl-3 pr-12 m-0.5 rounded text-slate-600 font-medium hover:bg-slate-100 transition-colors duration-100 text-left text-xs cursor-pointer ${
        checked ? "bg-blue-500 text-white hover:!bg-blue-500" : ""
      }`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export default DropdownDevicePointButton;
