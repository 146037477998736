import React, { useEffect, useRef, useState } from "react";

interface Props {
  children: React.ReactNode;
  displayDropdown: boolean;
  closeDropdown: () => void;
  parentRef: React.RefObject<HTMLElement>;
}

function useOutsideHTMLElementClick(
  ref: React.RefObject<HTMLDivElement>,
  parentRef: React.RefObject<HTMLElement>,
  callback: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        parentRef.current &&
        !parentRef.current.contains(event.target as Node)
      ) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, parentRef, callback]);
}

const Dropdown: React.FC<Props> = ({
  displayDropdown,
  closeDropdown,
  children,
  parentRef,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  useOutsideHTMLElementClick(dropdownRef, parentRef, closeDropdown);

  useEffect(() => {
    if (displayDropdown && parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      setPosition({
        top: parentRect.height + 5, // Position dropdown below the parent + 5 of margin
        left: 0, // Align dropdown with parent
      });
    }
  }, [displayDropdown, parentRef]);

  if (!displayDropdown) return null;

  return (
    <div
      ref={dropdownRef}
      className="z-30 bg-white rounded shadow absolute"
      style={{
        top: position.top,
        left: position.left,
        position: "absolute",
      }}
    >
      {children}
    </div>
  );
};

export default Dropdown;
