export interface Device {
  name: string;
  checked: boolean;
  buildingId: string;
  levelId: string;
  roomId: string;
}

export interface Point {
  name: string;
  checked: boolean;
}

export interface Building {
  id: string;
  name: string;
  checked: boolean;
  isFocused: boolean;
  levels: BuildingLevel[];
}

export interface BuildingLevel {
  id: string;
  name: string;
  checked: boolean;
}

export interface Room {
  id: string;
  name: string;
  checked: boolean;
  buildingId: string;
  levelId: string;
}

const devices: Device[] = [
  {
    name: "ACU-00009",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Macarthy Room",
  },
  {
    name: "ACU-00010",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Goods Inwards",
  },
  {
    name: "ACU-00011",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Production Office East",
  },
  {
    name: "ACU-00012",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Production Office West",
  },
  {
    name: "ACU-00019",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "SPC Build Area",
  },
  {
    name: "ACU-00020",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Production Office South",
  },
  {
    name: "ACU-00021",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Production Office North",
  },
  {
    name: "ACU-00030",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Reception",
  },
  {
    name: "ACU-00031",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Canteen",
  },
  {
    name: "ACU-00032",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Faraday Room",
  },
  {
    name: "ACU-000169",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
    roomId: "Faraday Room",
  },
  {
    name: "ACU-01174",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
    roomId: "Hot Desk Area West",
  },
  {
    name: "ACU-01178",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
    roomId: "Hot Desk Area North West",
  },
  {
    name: "ACU-01015",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
    roomId: "Hot Desk Area East",
  },
  {
    name: "ACU-01016",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
    roomId: "Hot Desk Area North East",
  },
];

const acuPoints: Point[] = [
  { name: "fan_speed", checked: false },
  { name: "run_mode", checked: false },
  { name: "run_status", checked: false },
  { name: "temperature", checked: false },
  { name: "temperature_setpoint", checked: false },
];

const buildings: Building[] = [
  {
    id: "Waterside",
    name: "Waterside",
    checked: true,
    isFocused: true,
    levels: [
      { id: "Level-00", name: "Ground floor", checked: true },
      { id: "Level-01", name: "Level 01", checked: false },
    ],
  },
];

const rooms: Room[] = [
  {
    id: "Macarthy Room",
    name: "Macarthy Room",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "Goods Inwards",
    name: "Goods Inwards",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "Production Office East",
    name: "Production Office East",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "Production Office West",
    name: "Production Office West",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "SPC Build Area",
    name: "SPC Build Area",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "Production Office South",
    name: "Production Office South",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "Production Office North",
    name: "Production Office North",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "Reception",
    name: "Reception",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "Canteen",
    name: "Canteen",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },
  {
    id: "Faraday Room",
    name: "Faraday Room",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-00",
  },

  {
    id: "Hot Desk Area West",
    name: "Hot Desk Area West",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Hot Desk Area North West",
    name: "Hot Desk Area North West",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Hot Desk Area East",
    name: "Hot Desk Area East",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Hot Desk Area North East",
    name: "Hot Desk Area North East",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Administration Office North",
    name: "Administration Office North",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Service Department",
    name: "Service Department",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Angela Office",
    name: "Angela Office",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Administration Office South",
    name: "Administration Office South",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Mei Office",
    name: "Mei Office",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Executive Office",
    name: "Executive Office",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Media Room",
    name: "Media Room",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
  {
    id: "Reception-2",
    name: "Reception",
    checked: false,
    buildingId: "Waterside",
    levelId: "Level-01",
  },
];

export const hardCoded = { buildings, rooms, devices, acuPoints };
