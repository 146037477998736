import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface DropdownDeviceButtonProps {
  name: string;
  checked: boolean;
  onClick: () => void;
  checkIcon: IconProp;
}

const DropdownDeviceButton: React.FC<DropdownDeviceButtonProps> = ({
  name,
  checked,
  onClick,
  checkIcon,
}) => {
  return (
    <button
      className={`py-2 pl-3 pr-3 m-0.5 rounded text-slate-600 font-medium hover:bg-slate-100 transition-colors duration-100 text-left text-xs cursor-pointer flex justify-between ${
        checked ? "bg-slate-200 hover:!bg-slate-200" : ""
      }`}
      onClick={onClick}
    >
      {name}
      <span>
        {checked && (
          <FontAwesomeIcon
            icon={checkIcon}
            size="sm"
            color="green"
            spin={false}
          />
        )}
      </span>
    </button>
  );
};

export default DropdownDeviceButton;
