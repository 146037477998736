import React, { useEffect, useRef, useState } from "react";
import CustomDropdown from "../dropdown-wrapper";
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import {
  Building,
  BuildingLevel,
  Device,
  hardCoded,
  Point,
  Room,
} from "./hard-coded";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DATE_RANGE, DATE_RANGE_MAP } from "./filter-config";
import DropdownDevicePointButton from "./components/dropdown-devicePoint-button";
import DropdownDeviceButton from "./components/dropdown-device-button";
import LuminaireDataService from "../../../firebase/services/luminaire.service";
import { HistoryIAcuData } from "../../../firebase/types/acu.type";
import { secondsToIso } from "../../../utils/timestamp";
import { modeToNumber } from "../../../utils/acstatus";
import {
  AcuChartData,
  CompareAcuChartData,
} from "../../../redux/types/chartData.type";

interface FocusedBuildingState {
  levels: BuildingLevel[];
  buildingIndex: number;
}

const GraphDropdown: React.FC<{
  handleFilterData: Function;
}> = ({ handleFilterData }) => {
  const checkIcon = faCheck as IconProp;

  const [loading, setLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<Nullable<(Date | null)[]>>(
    DATE_RANGE_MAP.WEEK_1.daterange
  );
  const [comparisonDates, setComparisontDates] = useState<
    Nullable<(Date | null)[]>
  >(generateComparisonDate(DATE_RANGE_MAP.WEEK_1.daterange));
  const [isCalendarDropdownVisible, setCalendarDropdownVisible] =
    useState(false);
  const [isBuildingDropdownVisible, setBuildingDropdownVisible] =
    useState(false);
  const [isRoomDropdownVisible, setRoomDropdownVisible] = useState(false);
  const [isDeviceDropdownVisible, setDeviceDropdownVisible] = useState(false);
  const [isPointDropdownVisible, setPointDropdownVisible] = useState(false);
  const [datePlaceholder, setDatePlaceholder] = useState<string>(
    DATE_RANGE.WEEK_1
  );

  const [buildings, setBuildings] = useState<Building[]>([
    ...hardCoded.buildings,
  ]);
  const [levelsOfFocusedBuilding, setlevelsOfFocusedBuilding] =
    useState<FocusedBuildingState>({
      levels: hardCoded.buildings[0].levels,
      buildingIndex: 0,
    });

  const [rooms, setRooms] = useState<Room[]>(hardCoded.rooms);
  const [devices, setDevices] = useState<Device[]>(hardCoded.devices);
  const [points, setPoints] = useState<Point[]>(hardCoded.acuPoints);

  const [comparePreviousPeriodChecked, setComparePreviousPeriodChecked] =
    useState<boolean>(false);

  const calendarFilterRef = useRef<HTMLDivElement>(null);
  const buildingFilterRef = useRef<HTMLDivElement>(null);
  const roomFilterRef = useRef<HTMLDivElement>(null);
  const deviceFilterRef = useRef<HTMLDivElement>(null);
  const pointFilterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateDevicesList(buildings);
    updateRoomsList(buildings);
  }, []);

  const toggleCalendarDropdown = () => {
    setCalendarDropdownVisible((prev) => !prev);
  };

  const closeCalendarDropdown = () => {
    setCalendarDropdownVisible(false);
  };

  const toggleBuildingDropdown = () => {
    setBuildingDropdownVisible((prev) => !prev);
  };

  const closeBuildingDropdown = () => {
    setBuildingDropdownVisible(false);
  };

  const toggleRoomDropdown = () => {
    setRoomDropdownVisible((prev) => !prev);
  };

  const closeRoomDropdown = () => {
    setRoomDropdownVisible(false);
  };

  const toggleDeviceDropdown = () => {
    setDeviceDropdownVisible((prev) => !prev);
  };

  const closeDeviceDropdown = () => {
    setDeviceDropdownVisible(false);
  };

  const togglePointDropdown = () => {
    setPointDropdownVisible((prev) => !prev);
  };

  const closePointDropdown = () => {
    setPointDropdownVisible(false);
  };

  const setDateRange = (dateRange: string) => {
    setDatePlaceholder(dateRange);
  };

  /*  
    INFO: This function takes date1 and date2 
    and output a single date that has 
    the date of date1 and the time of date2
  */
  const extractTimeOfDate = (
    date1: Date | null,
    date2: Nullable<Date>
  ): Date => {
    if (date1 == null || date2 == null) {
      return date1 == null ? new Date() : date1;
    }
    const combinedDate = new Date(date1);
    combinedDate.setHours(date2.getHours());
    combinedDate.setMinutes(date2.getMinutes());
    combinedDate.setSeconds(date2!.getSeconds());
    combinedDate.setMilliseconds(date2!.getMilliseconds());
    return combinedDate;
  };

  const formatDate = (date: Date | null): string => {
    if (date == null) {
      return "";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedHours = String(hours).padStart(2, "0");

    return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
  };

  const toggleRoomChecked = (index: number) => {
    const updatedRooms = [...rooms];
    updatedRooms[index].checked = !rooms[index].checked;
    setRooms(updatedRooms);
    setDevices(devices.map((device) => ({ ...device, checked: false })));
    setPoints(points.map((point) => ({ ...point, checked: false })));
    updateDevicesList(buildings);
  };

  const toggleDeviceChecked = (index: number) => {
    const updatedDevices = [...devices];
    updatedDevices[index].checked = !devices[index].checked;
    setDevices(updatedDevices);
    if (!updatedDevices.find((device) => device.checked)) {
      // const updatedPoints = points.map((point) => (point.checked = false));
      setPoints(points.map((point) => ({ ...point, checked: false })));
    }
  };

  const togglePointChecked = (index: number) => {
    const updatedPoints = [...points];
    updatedPoints[index].checked = !points[index].checked;
    setPoints(updatedPoints);
  };

  const toggleBuildingChecked = (index: number) => {
    const updatedBuildings = [...buildings];
    let indexOfPreviousFocusBuilding = updatedBuildings.findIndex(
      (building) => building.isFocused === true
    );
    if (indexOfPreviousFocusBuilding !== -1) {
      updatedBuildings[indexOfPreviousFocusBuilding].isFocused = false;
    }
    updatedBuildings[index].isFocused = true;
    setBuildings(updatedBuildings);
    setlevelsOfFocusedBuilding({
      levels: updatedBuildings[index].levels,
      buildingIndex: index,
    });
  };

  const toggleBuildingLevelChecked = (index: number) => {
    const updatedBuildings = [...buildings];
    updatedBuildings[levelsOfFocusedBuilding.buildingIndex].levels[
      index
    ].checked =
      !buildings[levelsOfFocusedBuilding.buildingIndex].levels[index].checked;

    let shouldBuildingBeMarkedAsSelected = updatedBuildings[
      levelsOfFocusedBuilding.buildingIndex
    ].levels.find((level) => level.checked);
    if (shouldBuildingBeMarkedAsSelected) {
      updatedBuildings[levelsOfFocusedBuilding.buildingIndex].checked = true;
    } else {
      updatedBuildings[levelsOfFocusedBuilding.buildingIndex].checked = false;
    }

    setBuildings(updatedBuildings);
    setlevelsOfFocusedBuilding({
      levels: updatedBuildings[levelsOfFocusedBuilding.buildingIndex].levels,
      buildingIndex: levelsOfFocusedBuilding.buildingIndex,
    });
    updateDevicesList(buildings);
    updateRoomsList(buildings);
  };

  const updateRoomsList = (buildings: Building[]) => {
    let resetHardCodedRooms = hardCoded.rooms.map((room) => ({
      ...room,
      checked: false,
    }));
    let roomsList: Room[] = [...resetHardCodedRooms];
    const checkedBuildings = buildings.filter((building) => building.checked);
    const checkedBuildingLevelPairs = new Set(
      checkedBuildings.flatMap((building) =>
        building.levels
          .filter((level) => level.checked)
          .map((level) => `${building.id}-${level.id}`)
      )
    );
    const updatedRoomsList = roomsList.filter((room) => {
      const roomKey = `${room.buildingId}-${room.levelId}`;
      if (checkedBuildingLevelPairs.has(roomKey)) {
        return room;
      }
    });
    if (updatedRoomsList != null) {
      setRooms(updatedRoomsList);
    }
  };

  const updateDevicesList = (buildings: Building[]) => {
    let resetHardCodedDevice = hardCoded.devices.map((device) => ({
      ...device,
      checked: false,
    }));
    let devicesList: Device[] = [...resetHardCodedDevice];
    const checkedBuildings = buildings.filter((building) => building.checked);

    const checkedBuildingLevelPairs = new Set(
      checkedBuildings.flatMap((building) =>
        building.levels
          .filter((level) => level.checked)
          .map((level) => `${building.id}-${level.id}`)
      )
    );

    const updatedDevicesList = devicesList.filter((device) => {
      const deviceKey = `${device.buildingId}-${device.levelId}`;

      let deviceIsInRoom = true;
      const checkedRooms = rooms
        .filter((room) => room.checked)
        .map((room) => room.name);
      if (checkedRooms.length > 0) {
        deviceIsInRoom = checkedRooms.includes(device.roomId);
      }

      if (checkedBuildingLevelPairs.has(deviceKey) && deviceIsInRoom) {
        return device;
      }
    });
    if (updatedDevicesList != null) {
      setDevices(updatedDevicesList);
    }
  };

  function countDropdownSelection<T>(array: T[], field: keyof T): number {
    return array.reduce(
      (count, obj) => (obj[field] === true ? count + 1 : count),
      0
    );
  }

  // TODO: this function should be able to generate a graph for multiple slected devices
  // At the moment it only generates for one device only
  const generateGraph = async () => {
    if (dates == null || dates.includes(null)) {
      return console.error(
        "[ERROR]: Dates wrongly defined - cannot generate graph"
      );
    }
    const startDate = dates![0];
    const endDate = dates![1];
    const levelId = getSelectedBuildingLevelId();
    const firstSelectedDevices = getFirstSelectedDevice(levelId);

    if (!firstSelectedDevices) {
      return console.error(
        "[ERROR]: No device selected - cannot generate graph"
      );
    }
    setLoading(true);

    let queryResult = await makeApiRequest(
      levelId,
      firstSelectedDevices.name,
      startDate!,
      endDate!
    );
    let chartDisplay = transformPointsToChartDisplay(points);
    let chartData = flattenObjects(queryResult);
    handleFilterData(chartData, chartDisplay);

    // only run if compare checkbox is selected
    if (comparePreviousPeriodChecked) {
      if (comparisonDates == null || comparisonDates.includes(null)) {
        return console.error(
          "[ERROR]: Dates wrongly defined - cannot generate graph"
        );
      }
      const compareStartDate = comparisonDates[0];
      const compareEndDate = comparisonDates[1];
      let comparedQueryResult = await makeApiRequest(
        levelId,
        firstSelectedDevices.name,
        compareStartDate!,
        compareEndDate!
      );
      chartData = mergeChartData(
        chartData,
        flattenCompareObjects(comparedQueryResult)
      );
      chartDisplay = {
        ...chartDisplay,
        compare_fan_speed: true,
        compare_run_mode: true,
        compare_run_status: true,
        compare_temperature: true,
        compare_temperature_setpoint: true,
      };
      handleFilterData(chartData, chartDisplay);
    }
    setLoading(false);
  };

  const makeApiRequest = async (
    levelId: string,
    deviceName: string,
    startDate: Date,
    endDate: Date
  ): Promise<HistoryIAcuData[]> => {
    try {
      const querySnapshot = await LuminaireDataService.getDeviceTimeseries(
        levelId,
        deviceName,
        startDate,
        endDate
      ).get();

      return querySnapshot.docs.map(
        (doc: any) => doc.data() as HistoryIAcuData
      );
    } catch (error) {
      console.error("[ERROR] Error getting documents:", error);
      return [];
    }
  };

  const flattenObjects = (historyObjectArray: HistoryIAcuData[]) => {
    return historyObjectArray.map((item) => {
      const { timestamp, points } = item;

      return {
        timestamp: secondsToIso(timestamp.seconds),
        fan_speed: modeToNumber(points.fan_speed?.present_value) ?? 0,
        run_mode: modeToNumber(points.run_mode?.present_value) ?? 0,
        run_status: modeToNumber(points.run_status?.present_value) ?? 0,
        temperature: points.temperature?.present_value ?? 0,
        temperature_setpoint: points.temperature_setpoint?.present_value ?? 0,
        compare_fan_speed: 0,
        compare_run_mode: 0,
        compare_run_status: 0,
        compare_temperature: 0,
        compare_temperature_setpoint: 0,
      };
    });
  };

  const flattenCompareObjects = (historyObjectArray: HistoryIAcuData[]) => {
    return historyObjectArray.map((item) => {
      const adjustedTimestamp = item.timestamp.seconds + 24 * 365 * 60 * 60;
      return {
        timestamp: secondsToIso(adjustedTimestamp),
        compare_fan_speed:
          modeToNumber(item.points.fan_speed?.present_value) ?? 0,
        compare_run_mode:
          modeToNumber(item.points.run_mode?.present_value) ?? 0,
        compare_run_status:
          modeToNumber(item.points.run_status?.present_value) ?? 0,
        compare_temperature: item.points.temperature?.present_value ?? 0,
        compare_temperature_setpoint:
          item.points.temperature_setpoint?.present_value ?? 0,
      };
    });
  };

  const mergeChartData = (
    originalData: AcuChartData,
    compareData: CompareAcuChartData
  ): AcuChartData => {
    return originalData.map((data) => {
      const compare = compareData.find(
        (compareItem) => compareItem.timestamp === data.timestamp
      );
      if (compare) {
        return { ...data, ...compare };
      }
      return data;
    });
  };

  const transformPointsToChartDisplay = (
    points: Point[]
  ): { [key: string]: boolean } => {
    return points.reduce((acc, point) => {
      acc[point.name] = point.checked;
      return acc;
    }, {} as { [key: string]: boolean });
  };

  const getSelectedBuildingLevelId = (): string => {
    const selectedBuilding = buildings.find((building) => building.checked);
    const selectedLevel = selectedBuilding?.levels.find(
      (level) => level.checked
    );
    if (selectedLevel) {
      return selectedLevel.id;
    }
    return "Level-00";
  };

  function generateComparisonDate(date: Nullable<(Date | null)[]>) {
    if (date == null || date.includes(null)) {
      return date;
    }
    let startDate = new Date(date[0]!.getTime());
    startDate.setTime(startDate.getTime() - 24 * 7 * 60 * 60 * 1000);
    let endDate = new Date(date[1]!.getTime());
    endDate.setTime(endDate.getTime() - 24 * 7 * 60 * 60 * 1000);
    return [startDate, endDate];
  }

  const getFirstSelectedDevice = (levelId: string): Device => {
    const selectedDevice = devices.find(
      (device) => device.checked && device.levelId === levelId
    );
    if (selectedDevice) {
      return selectedDevice;
    }
    return hardCoded.devices[0]; // TODO: remove this once graph is able to display multiple devices at once
  };

  const setCalendarDates = (dates: Nullable<(Date | null)[]>) => {
    setDates(dates);
    setComparisontDates(generateComparisonDate(dates));
  };

  return (
    <div className="flex justify-between">
      <div className="flex">
        <div className="relative mr-2">
          <div
            ref={calendarFilterRef}
            className="inline-block text-black rounded cursor-pointer !border border-slate-300 hover:bg-slate-50 p-2"
            onClick={toggleCalendarDropdown}
          >
            <p className="text-slate-600">
              <label className="bg-slate-300 rounded-lg p-0.5 px-2 text-slate-600 font-light mr-2 mb-0">
                {/* TODO: If custom range date is selected, this label should say 'Custom Range' */}
                {datePlaceholder}
              </label>
              {formatDate(dates![0])}{" "}
              <span className="mx-2 text-slate-400">|</span>{" "}
              {formatDate(dates![1])}
            </p>
          </div>

          <CustomDropdown
            displayDropdown={isCalendarDropdownVisible}
            closeDropdown={closeCalendarDropdown}
            // TODO: on dropdown close check both date are valid
            parentRef={calendarFilterRef}
          >
            <div className="rounded-md border border-slate-400 flex">
              <div className="flex flex-col pt-3 border-r border-r-slate-200 w-36">
                {Object.values(DATE_RANGE_MAP).map((date) => (
                  <button
                    key={date.label}
                    className={`py-2 pl-3 pr-12 m-0.5 rounded text-slate-600 font-medium hover:bg-slate-100 transition-colors duration-100 text-left text-xs ${
                      date.label === datePlaceholder
                        ? "bg-blue-500 text-white hover:!bg-blue-500"
                        : ""
                    }`}
                    onClick={(e) => {
                      setDateRange(date.label);
                      setCalendarDates(date.daterange);
                    }}
                  >
                    {date.label}
                  </button>
                ))}
              </div>
              <PrimeReactProvider>
                <div className="flex justify-content-center flex-col">
                  <Calendar
                    value={dates}
                    onChange={(e) => setCalendarDates(e.value)}
                    selectionMode="range"
                    maxDate={new Date()}
                    readOnlyInput
                    hideOnRangeSelection
                    inline
                  />
                  <div className="flex justify-content-center">
                    <div className="border-r-2 flex flex-col text-center">
                      <label className="text-slate-500 font-medium">
                        Start Time
                      </label>
                      <Calendar
                        value={dates![0]}
                        onChange={(e) =>
                          setCalendarDates([
                            extractTimeOfDate(dates![0], e.value),
                            dates![1],
                          ])
                        }
                        maxDate={new Date()}
                        timeOnly
                        hourFormat="24"
                        inline
                      />
                    </div>
                    <div className="flex flex-col text-center">
                      <label className="text-slate-500 font-medium">
                        End Time
                      </label>
                      <Calendar
                        value={dates![1]}
                        onChange={(e) =>
                          setCalendarDates([
                            extractTimeOfDate(dates![0], e.value),
                            dates![1],
                          ])
                        }
                        minDate={dates![0] as Date} // end date should never go before start date
                        maxDate={new Date()}
                        timeOnly
                        hourFormat="24"
                        inline
                      />
                    </div>
                  </div>
                </div>
              </PrimeReactProvider>
            </div>
          </CustomDropdown>
        </div>

        {/* ******** BUILDING DROPDOWN ***** */}
        <div className="relative mr-2">
          <div
            ref={buildingFilterRef}
            className="inline-block text-black rounded cursor-pointer !border border-slate-300 hover:bg-slate-50 p-2.5"
            onClick={toggleBuildingDropdown}
          >
            {countDropdownSelection(buildings, "checked") === 0 ? (
              <p className="text-slate-400">Choose building / level</p>
            ) : (
              <p className="text-slate-600">
                {`${countDropdownSelection(
                  buildings,
                  "checked"
                )} building selected`}
              </p>
            )}
          </div>
          <CustomDropdown
            displayDropdown={isBuildingDropdownVisible}
            closeDropdown={closeBuildingDropdown}
            parentRef={buildingFilterRef}
          >
            <div className="rounded-md border border-slate-400 flex">
              <div className="flex flex-col border-r border-r-slate-200 w-48">
                <label className="py-2 pl-3 pr-3 m-0.5 text-slate-600 font-medium text-lg border-b">
                  Buildings
                </label>
                {buildings.map((building, index) => (
                  <DropdownDeviceButton
                    key={building.id}
                    name={building.name}
                    // isFocused={building.isFocused}
                    checked={building.checked}
                    checkIcon={checkIcon}
                    onClick={() => toggleBuildingChecked(index)}
                  />
                ))}
              </div>
              <div className="flex flex-col border-r border-r-slate-200 w-72">
                <label className="py-2 pl-3 pr-3 m-0.5 text-slate-600 font-semibold text-lg border-b">
                  {buildings[levelsOfFocusedBuilding.buildingIndex].name}
                </label>
                {levelsOfFocusedBuilding.levels.map((level, index) => (
                  <DropdownDevicePointButton
                    key={level.name}
                    name={level.name}
                    checked={level.checked}
                    onClick={() => toggleBuildingLevelChecked(index)}
                  />
                ))}
              </div>
            </div>
          </CustomDropdown>
        </div>

        {/* ******** ROOM DROPDOWN ***** */}
        <div className="relative mr-2">
          <div
            ref={roomFilterRef}
            className="inline-block text-black rounded cursor-pointer !border border-slate-300 hover:bg-slate-50 p-2.5"
            onClick={toggleRoomDropdown}
          >
            {countDropdownSelection(rooms, "checked") === 0 ? (
              <p className="text-slate-400">Choose room</p>
            ) : (
              <p className="text-slate-600">
                {`${countDropdownSelection(rooms, "checked")} rooms selected`}
              </p>
            )}
          </div>

          <CustomDropdown
            displayDropdown={isRoomDropdownVisible}
            closeDropdown={closeRoomDropdown}
            parentRef={roomFilterRef}
          >
            <div className="rounded-md border border-slate-400 flex">
              <div className="flex flex-col border-r border-r-slate-200 w-48">
                <label className="py-2 pl-3 pr-3 m-0.5 text-slate-600 font-medium text-lg border-b">
                  Room list
                </label>
                {rooms.map((room, index) => (
                  <DropdownDeviceButton
                    key={room.id}
                    name={room.name}
                    checked={room.checked}
                    checkIcon={checkIcon}
                    onClick={() => toggleRoomChecked(index)}
                  />
                ))}
              </div>
            </div>
          </CustomDropdown>
        </div>

        {/* ******** DEVICE DROPDOWN ***** */}
        <div className="relative mr-2">
          <div
            ref={deviceFilterRef}
            className="inline-block text-black rounded cursor-pointer !border border-slate-300 hover:bg-slate-50 p-2.5"
            onClick={toggleDeviceDropdown}
          >
            {countDropdownSelection(devices, "checked") === 0 ? (
              <p className="text-slate-400">Choose assets</p>
            ) : (
              <p className="text-slate-600">
                {`${countDropdownSelection(
                  devices,
                  "checked"
                )} device selected`}
              </p>
            )}
          </div>

          <CustomDropdown
            displayDropdown={isDeviceDropdownVisible}
            closeDropdown={closeDeviceDropdown}
            parentRef={deviceFilterRef}
          >
            {devices == null || devices.length === 0 ? (
              <div className="rounded-md border border-slate-400 flex">
                <div className="flex flex-col w-43 p-3 text-xs text-slate-400 italic">
                  Please select building that contains assets to see list
                </div>
              </div>
            ) : (
              <div className="rounded-md border border-slate-400 flex">
                <div className="flex flex-col border-r border-r-slate-200 w-48">
                  <label className="py-2 pl-3 pr-3 m-0.5 text-slate-600 font-medium text-lg border-b">
                    Assets list
                  </label>
                  {devices.map((device, index) => (
                    <DropdownDeviceButton
                      key={device.name}
                      name={device.name}
                      // isFocused={device.isFocused}
                      checked={device.checked}
                      checkIcon={checkIcon}
                      onClick={() => toggleDeviceChecked(index)}
                    />
                  ))}
                </div>
              </div>
            )}
          </CustomDropdown>
        </div>
        {/* ******** POINTS DROPDOWN ***** */}
        <div className="relative mr-2">
          <div
            ref={pointFilterRef}
            className="inline-block text-black rounded cursor-pointer !border border-slate-300 hover:bg-slate-50 p-2.5"
            onClick={togglePointDropdown}
          >
            {countDropdownSelection(points, "checked") === 0 ? (
              <p className="text-slate-400">Choose points</p>
            ) : (
              <p className="text-slate-600">
                {`${countDropdownSelection(points, "checked")} points selected`}
              </p>
            )}
          </div>
          <CustomDropdown
            displayDropdown={isPointDropdownVisible}
            closeDropdown={closePointDropdown}
            parentRef={pointFilterRef}
          >
            {devices.find((device) => device.checked) ? (
              <div className="rounded-md border border-slate-400 flex">
                <div className="flex flex-col border-r border-r-slate-200 w-48">
                  <label className="py-2 pl-3 pr-3 m-0.5 text-slate-600 font-medium text-lg border-b">
                    Points list
                  </label>
                  {points.map((point, index) => (
                    <DropdownDeviceButton
                      key={point.name}
                      name={point.name}
                      checked={point.checked}
                      checkIcon={checkIcon}
                      onClick={() => togglePointChecked(index)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="rounded-md border border-slate-400 flex">
                <div className="flex flex-col w-43 p-3 text-xs text-slate-400 italic">
                  Please select at least one device to point see list
                </div>
              </div>
            )}
          </CustomDropdown>
        </div>

        {/* ******** COMPARE CHECKBOX ***** */}
        <div className="flex items-center cursor-pointer">
          <input
            id="default-checkbox"
            type="checkbox"
            style={{ width: "14px", height: "14px" }}
            checked={comparePreviousPeriodChecked}
            onChange={(e) => setComparePreviousPeriodChecked(e.target.checked)}
            className="mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="default-checkbox"
            className="text-sm font-light text-gray-700 dark:text-gray-500 !p-0 !m-0 cursor-pointer leading-4"
          >
            Compare selection with <br />
            previous period
          </label>
        </div>
      </div>

      {/* ******** GENERATE GRAPH BUTTON ***** */}
      <div
        className="flex items-center text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 cursor-pointer"
        onClick={generateGraph}
      >
        {loading && (
          <div role="status">
            <svg
              aria-hidden="true"
              className="h-4 me-2 text-gray-50 animate-spin dark:text-gray-50 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
        Generate graph
      </div>
    </div>
  );
};

export default GraphDropdown;
