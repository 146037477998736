export enum DATE_RANGE {
  HOUR_1 = "1 Hour",
  HOURS_3 = "3 Hours",
  HOURS_12 = "12 Hours",
  DAY_1 = "1 Day",
  DAYS_3 = "3 Days",
  WEEK_1 = "1 Week",
  WEEKS_2 = "2 Weeks",
  WEEKS_4 = "4 Weeks",
  MONTHS_2 = "2 Months",
  MONTHS_3 = "3 Months",
  MONTHS_6 = "6 Months",
  YEAR_1 = "1 Year",
  CUSTOM = "Custom Date Range",
}

export const DATE_RANGE_MAP = {
  HOUR_1: {
    label: DATE_RANGE.HOUR_1,
    daterange: [
      new Date(new Date().getTime() - 1 * 60 * 60 * 1000),
      new Date(),
    ],
  },
  HOUR_3: {
    label: DATE_RANGE.HOURS_3,
    daterange: [
      new Date(new Date().getTime() - 3 * 60 * 60 * 1000),
      new Date(),
    ],
  },
  HOUR_12: {
    label: DATE_RANGE.HOURS_12,
    daterange: [
      new Date(new Date().getTime() - 12 * 60 * 60 * 1000),
      new Date(),
    ],
  },
  DAYS_1: {
    label: DATE_RANGE.DAY_1,
    daterange: [
      new Date(new Date().setDate(new Date().getDate() - 1)),
      new Date(),
    ],
  },
  DAYS_3: {
    label: DATE_RANGE.DAYS_3,
    daterange: [
      new Date(new Date().setDate(new Date().getDate() - 3)),
      new Date(),
    ],
  },
  WEEK_1: {
    label: DATE_RANGE.WEEK_1,
    daterange: [
      new Date(new Date().setDate(new Date().getDate() - 7)),
      new Date(),
    ],
  },
  WEEKS_2: {
    label: DATE_RANGE.WEEKS_2,
    daterange: [
      new Date(new Date().setDate(new Date().getDate() - 14)),
      new Date(),
    ],
  },
  WEEKS_4: {
    label: DATE_RANGE.WEEKS_4,
    daterange: [
      new Date(new Date().setDate(new Date().getDate() - 28)),
      new Date(),
    ],
  },
  MONTHS_2: {
    label: DATE_RANGE.MONTHS_2,
    daterange: [
      new Date(new Date().setMonth(new Date().getMonth() - 2)),
      new Date(),
    ],
  },
  MONTHS_3: {
    label: DATE_RANGE.MONTHS_3,
    daterange: [
      new Date(new Date().setMonth(new Date().getMonth() - 3)),
      new Date(),
    ],
  },
  MONTHS_6: {
    label: DATE_RANGE.MONTHS_6,
    daterange: [
      new Date(new Date().setMonth(new Date().getMonth() - 6)),
      new Date(),
    ],
  },
  YEAR_1: {
    label: DATE_RANGE.YEAR_1,
    daterange: [
      new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      new Date(),
    ],
  },
};
