import React, { useCallback, useState, useEffect } from "react";

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { StoreState } from '../../redux/types/store.type';

import {setEnableMobileMenu, ThemeOptionsState} from "../../redux/types/themeOptions.type";
import {sEnableMobileMenu} from "../../redux/reducers/ThemeOptions";

// import MetisMenu from "react-metismenu-badge";

import Menu from './Menu';

import {
    MonitoringNav,
    FaultNav,
    CommNav,
    ChartNav,
    NewNav,
    SimmtronicNav,
    ElectracomNav,
    FutureNav,
    NavMenuItem,
    EmptyNav
  } from "./NavItems";

import ErrorBoundary from "../../ErrorBoundary";

import UserDataService from "../../firebase/services/user.service";
import firebase from "firebase/compat/app";

interface OwnProps {
  user: firebase.User | null
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
};

type MapDispatchToPropsType = {
    setEnableMobileMenu: (enable: boolean) => void;
};
  
type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType & MapDispatchToPropsType;

const useThemeActions = () => {
    const dispatch = useDispatch<ThunkDispatch<setEnableMobileMenu, unknown, AnyAction>>();
  
    const memoizedSetEnableMobileMenu = useCallback((enable: boolean) => dispatch(sEnableMobileMenu(enable)), [dispatch]);

    return {
        setEnableMobileMenu: memoizedSetEnableMobileMenu,
    };
};

const Nav: React.FC<Props> = ({ ThemeOptions, setEnableMobileMenu, user }) => {
    
  const [navigation, setNavigation] = useState<NavMenuItem[]>(EmptyNav); // Use state for logoBranding
  useEffect(() => {
      setEnableMobileMenu(true);
      if (user && user.uid) {
        UserDataService.getBrandingByUserId(user.uid)
            .then(brand => {
                if (brand === "electracom") {
                  setNavigation(ElectracomNav); // Update state
                } else if (brand === "simmtronic") {
                  setNavigation(SimmtronicNav); // Update state
                } else if (brand === "future") {
                  setNavigation(FutureNav); // Update state
                } else {
                  setNavigation(NewNav); // Update state
                }
            })
            .catch(error => {
                console.error('Error fetching branding:', error);
            });
    } else {
        console.log('User or User UID is undefined');
    }
    }, [setEnableMobileMenu, user]);

    const toggleMobileSidebar = () => {
        setEnableMobileMenu(!ThemeOptions.enableMobileMenu);
      };

    return (
        <>
         {/* <MetisMenu content={LevelNav} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> */}

        {/* <h5 className="app-sidebar__heading">Floor Plans</h5> */}
        {/* <MetisMenu content={FaultNav} onSelected={toggleMobileSidebar}
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> */}

        <Menu content={navigation} onSelected={toggleMobileSidebar} className="vertical-nav-menu" classNameStateIcon="pe-7s-angle-down" open={ThemeOptions.enableMobileMenu}/>

        {/* <h5 className="app-sidebar__heading">Charts</h5> */}
        {/* <MetisMenu content={ChartNav} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> */}

        {/* <h5 className="app-sidebar__heading">Development Plant</h5> */}
        {/* <MetisMenu content={MonitoringNav} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> */}
        </>
    );
};
  
const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions,
    };
};
  
const connector = connect(mapStateToProps);

export default connector((props: Props) => {
    const { setEnableMobileMenu } = useThemeActions();

    return (
      <ErrorBoundary fallback={<div>Oops! Something went wrong.</div>}>
      <Nav {...props} setEnableMobileMenu={setEnableMobileMenu} />
      </ErrorBoundary>
      );
});